import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import insertImage from './insert-image.svg'
class Image2 extends Plugin {
    static get pluginName() {
        return 'image2';
    }

    constructor(editor, config = {}) {
        super(editor);
        this.editor = editor;
        this.url = editor.config.get('image2.url') || 'http://example.com';
        this.listener = null;
    }

    init() {
        const editor = this.editor;
        const url = this.url;

        editor.ui.componentFactory.add('image2', locale => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Chọn ảnh từ thư viện',
                icon: insertImage,
                tooltip: true,
            });
           
            view.on('execute', () => {
                const selectWindow = popupCenter({ url: url, title: 'Image Selector', w: 1024, h: 600 });
                
                // Tạo hàm callback riêng để xử lý sự kiện truyền từ window
                const handleMessage = (event) => {
                    if (event.origin !== window.location.origin) return;
                    let selectedImages = event.data.selectedImages;
                    if (selectedImages && selectedImages.length > 0) {
                        const imageUtils = editor.plugins.get('ImageUtils');
                        imageUtils.insertImage({ src: selectedImages[0] });
                    }
                };
    
                window.addEventListener('message', handleMessage);
            });
    
            return view;
        });

        // Add a listener to the editor#destroy event to remove the window listener
        editor.on('destroy', () => {
            if (this.listener) {
                window.removeEventListener('message', this.listener);
            }
        });
    }

    destroy() {
        if (this.listener) {
            window.removeEventListener('message', this.listener);
        }
    }
}
const popupCenter = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, 
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
}
export default Image2;
